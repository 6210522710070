import $ from 'jquery'
import 'foundation-sites'
import { Turbo } from '@hotwired/turbo-rails'
import initializeIcons from './icons.js'

Turbo.session.drive = false
$(document).foundation()

document.addEventListener('DOMContentLoaded', function (event) {
  initializeIcons()
}, false)
